<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid" id="email">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="Card">
                <img class="img-fluid" src="@/assets/Images/Formation/Grupo 973.png" alt="message" />
                <div class="title">
                  <h1>{{locale[lang].EMAILS.TITLE}}</h1>
                </div>
                <h5 v-html="locale[lang].EMAILS.CONTENT">
                </h5>
                <form id="contact_form">
                  <div class="input-group">
                    <input type="text" name="user-email" id="user-email" v-model="email"
                      :placeholder="locale[lang].EMAILS.PLACEHOLDER" class="form-control" />
                  </div>
                  <div class="input-group">
                    <input type="submit" :value="locale[lang].EMAILS.BUTTON" />
                  </div>
                </form>
                <p>
                  <i class="fas fa-lock"></i>
                  {{locale[lang].EMAILS.LOCK}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
  
export default {
  components: {
    NavBar,
    Footer,
  },
  mounted() {
    window.emailjs.init("user_od4hxjkv6iNt08Hd5vJPS");
    const form = document.getElementById("contact_form");
    form.addEventListener("submit", (event) => {
      event.preventDefault();
      if ( !this.email || this.email.trim() == "") {
        return alert("Please fill the email field");
      }
      window.emailjs
        .sendForm("service_7ser76b", "template_qiid335", form)
        .then(() =>
          this.$swal("Success", "Message sent successfully!", "success")
        )
        .catch(console.error)
        .finally(() => window.$("#contact-modal").modal("hide"));
    });
  },
  data() {
    return {
      email: "",
    };
  },
};
</script>

<style scoped>
#email {
  padding: 80px 15%;
  background-image: url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Background/Grupo 1025.png"),
    url("../assets/Images/Background/Grupo 1027.png");
  background-repeat: no-repeat;
  background-size: auto, 276px 242px, auto;
  background-position: 1% 85%, 95% 95%, 5% 5%;
}

.Card {
  background: #0f1010 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #93939340;
  border-radius: 30px;
  opacity: 1;
  padding: 43px;
}

.title {
  position: relative;
  margin-bottom: 30px;
}

.title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 61px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -12px;
}

.Card h1 {
  text-align: center;
  font: normal normal bold 38px/50px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.Card h5 {
  text-align: center;
  font: normal normal 500 20px/32px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 45px;
  opacity: 1;
}

.Card p {
  text-align: center;
  font: normal normal 500 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.fa-lock {
  color: #ff0313;
  margin-right: 10px;
}

.input-group input[type="text"] {
  border: 1px solid #707070;
  border-radius: 15px;
  opacity: 1;
  margin-bottom: 18px;
  font: normal normal 500 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  margin-bottom: 50px;
}

.input-group input[type="text"] {
  height: 64px;
}

.input-group input[type="text"]::placeholder {
  font: normal normal 500 15px/19px Montserrat;
  letter-spacing: 0px;
  color: #9e9e9e;
  opacity: 1;
  padding-left: 16px;
}

.input-group input[type="submit"] {
  text-align: center;
  font: normal normal bold 22px/27px Montserrat;
  color: #ffffff;
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-color: transparent;
  border: 1.7px solid #ffffff !important;
  border-radius: 15px;
  padding: 24px 0;
  width: 100%;
  margin-bottom: 45px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
  box-shadow: 0px 3px 50px #a7a5a54d;
  margin-bottom: 50px;
}

.input-group input[type="submit"]:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 12px;
}

.img-fluid {
  margin: 30px 0 40px 0;
}

@media (max-width: 825px) {
  #email {
    padding: 80px 15px;
  }

  .Card {
    padding: 43px 15px;
  }

  .Card h1 {
    font-size: 22px;
    line-height: 24px;
  }

  .Card h5 {
    font-size: 18px;
    line-height: 22px;
  }

  .title::after {
    left: 45%;
    bottom: -18px;
  }

  .Card p {
    font-size: 16px;
    line-height: 20px;
  }

  .input-group input[type="submit"] {
    font-size: 14px;
  }

  .input-group input[type="text"]::placeholderr {
    font: normal normal 500 14px/18px Montserrat;
  }
}

@media (max-width: 425px) {
  .title::after {
    left: 41%;
  }
}

@media (max-width: 375px) {
  .title::after {
    left: 39%;
  }
}
</style>
